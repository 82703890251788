/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useCallback, useState } from 'react';
import {

  Button, Grid, Typography
} from '@material-ui/core';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
  Breadcrumbs, FormCheckboxContainer, InfoIcon, MapContact,
  SkeletonAllowanceDetail, SkeletonNoAllowance, TagList, Wrapper, BannerMessage
} from 'components';
import { observer } from 'mobx-react-lite';
import { faHome } from '@fortawesome/pro-regular-svg-icons';
import {
  PATHS, ROUTES, ALLOWANCE_LEISURE_NATURE, ADOSSPP_ROLES, MISTERFLY_URL
} from 'utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { FormHelper, isMisterflyTicket, translate } from 'utils';
import { useStores, useCanBook } from 'hooks';
import { useSnackbar } from 'notistack';
import { MisterflyService } from 'services';
import { action } from 'mobx';
import { CookieHelper } from 'utils/helpers/CookieHelper';
import ImageTemplates from './ImageTemplates';

const AllowanceLeisureDetailContainer = styled.section`
  .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
  img {
    max-width: 90%;
    border-radius: 8px;
  }
`;

const BannerMessageContainer = styled(Grid)`
  margin-bottom: 20px;
`;

const AllowanceLeisureDetail = observer(() => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const { allowanceStore, userStore } = useStores();
  const { allowance, isLoading, allowanceCategoryMenuMapper } = allowanceStore;
  const { enqueueSnackbar } = useSnackbar();
  const [hasAlreadyBenefited, setHasAlreadyBenefited] = useState(true);
  const category = pathname.split('/')[2];

  useEffect(() => {
    window.scrollTo(0, 0);

    allowanceStore.getAllowanceDetail(id)
      .catch(() => enqueueSnackbar(translate('errors.failedAllowanceLeisureDetail'), { variant: 'error' }));
  }, [allowanceStore, id, enqueueSnackbar]);

  useEffect(() => {
    MisterflyService.getUserMisterflyHasAlreadyBenefited().then(action(alreadyBenefited => {
      setHasAlreadyBenefited(alreadyBenefited);
    })).catch(error => { throw error; });
  }, []);
  
  const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  };
  const handleReservation = async () => {
    try {
        await userStore.refreshToken();
        const token = CookieHelper.getCookie('token');
        if (token) {
          if(isSafari()){
            window.location.href = `${MISTERFLY_URL}?token_auth=${token}`;
          }else{
            window.open(`${MISTERFLY_URL}?token_auth=${token}`, '_blank');
          }
        } else {
            console.error('Token is not available after refresh');
        }
    } catch (err) {
        console.error('Failed to refresh token:', err);
    }
  };

  const handleScrollToConditions = useCallback(() => {
    document.getElementById('conditions').scrollIntoView({
      behavior: 'smooth'
    });
  }, []);

  const getTags = advantageTypes => advantageTypes.map(type => type.name);

  if (isLoading || !allowance) {
    return (
      <Wrapper>
        {isLoading ? <SkeletonAllowanceDetail isLeisure /> : <SkeletonNoAllowance />}
      </Wrapper>
    );
  }

  const roles = [];
  if (allowance.seniorityCondition) {
    roles.push(ADOSSPP_ROLES.FORMER);
  }
  if (allowance.activityCondition) {
    roles.push(ADOSSPP_ROLES.ACTIVE);
  }
  const canBook = useCanBook(roles);

  return (
    <Wrapper>
      <AllowanceLeisureDetailContainer>
        <Breadcrumbs>
          <Link to={PATHS.HOME}>
            <FontAwesomeIcon icon={faHome} />
          </Link>
          <Link to={PATHS.ALLOWANCE_LEISURE_MAIN}>
            {translate('menu.allowanceLeisure')}
          </Link>
          <Link to={ROUTES.ALLOWANCE_LEISURE_CATEGORY(category)}>
            {allowanceCategoryMenuMapper[category]}
          </Link>
        </Breadcrumbs>

        <Grid container direction="column" spacing={3}>
          <Grid item xs>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <BannerMessageContainer item sm={12}>
                  {
                    canBook.map(item => {
                      if (!item.value) {
                        return (
                          <BannerMessage key={item.name}>
                            {translate(`errors.${item.name}`)}
                          </BannerMessage>
                        );
                      }
                      return null;
                    })

                  }
                </BannerMessageContainer>
                <Grid container direction="row">
                  <Grid item sm={!allowance.description && !allowance.gainDescription ? 12 : 6} xs={12}>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography
                          style={{ textTransform: 'uppercase' }}
                          variant="h1"
                        >
                          {allowance.name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        {allowance.subCategory && (
                          <Typography component="h2">{allowance.subCategory.name}</Typography>
                        )}
                      </Grid>
                      <Grid item>
                        {allowance.advantageTypes && <TagList justify="flex-start" tags={getTags(allowance.advantageTypes)} />}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        {allowance.gainDescription && (
                          <InfoIcon info={(
                            <Typography>
                              {allowance.gainDescription}
                              <br />
                              {allowance.condition && (
                                <Button
                                  aria-label={translate('button.readConditions')}
                                  variant="text"
                                  onClick={handleScrollToConditions}
                                >
                                  {translate('button.readConditions')}
                                </Button>
                              )}
                            </Typography>
                          )}
                          />
                        )}
                        {allowance.description && (
                          <>
                            <Typography className="bold">{translate('common.description')}</Typography>
                            <Typography dangerouslySetInnerHTML={{ __html: allowance.description.replace(/\n/g, '<br />') }} />
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {(allowance.illustrations && allowance.illustrations.length > 0) && (
            <Grid item xs>
              <ImageTemplates images={allowance.illustrations} />
            </Grid>
          )}

          {(allowance.nature && allowance.nature.id !== ALLOWANCE_LEISURE_NATURE.PROMOTIONAL.code && allowance.coordinate) && (
            <Grid item xs>
              <MapContact
                address={allowance.coordinate.address}
                email={allowance.coordinate.mail}
                fax={allowance.coordinate.fax}
                tel={allowance.coordinate.phone}
                website={FormHelper.getExternalURL(allowance.coordinate.externalRedirectionLink || allowance.coordinate.website)}
              />
            </Grid>
          )}

          <Grid id="conditions" item xs>
            {(allowance.conditions || allowance.cancellationConditions) && (
              <FormCheckboxContainer>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Typography className="bold" component="h2" variant="h5">
                      {translate('common.conditons')}
                    </Typography>
                  </Grid>
                  {allowance.conditions && (
                    <Grid item>
                      <Typography>
                        Conditions :
                      </Typography>
                      <Typography dangerouslySetInnerHTML={{ __html: allowance.conditions.replace(/\n/g, '<br />') }} />
                    </Grid>
                  )}
                  {allowance.cancellationConditions && (
                    <Grid item>
                      <Typography>
                        Conditions d&apos;annulation :
                      </Typography>
                      <Typography
                        dangerouslySetInnerHTML={{ __html: allowance.cancellationConditions.replace(/\n/g, '<br />') }}
                      />
                    </Grid>
                  )}
                  {allowance.cancellationWebSite && (
                    <Grid item>
                      <Typography>
                        Site internet pour annulation :
                      </Typography>
                      <a
                        href={FormHelper.getExternalURL(allowance.cancellationWebSite)}
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        {allowance.cancellationWebSite}
                      </a>
                    </Grid>
                  )}
                </Grid>
              </FormCheckboxContainer>
            )}
            {(FormHelper.getCanSubmit(canBook)) && (
              <Grid container item justify="center">
                {(() => {
                  if (allowance.nature && allowance.nature.id === ALLOWANCE_LEISURE_NATURE.PROMOTIONAL.code) {
                    return (
                      <a
                        href={FormHelper.getExternalURL(allowance.coordinate.externalRedirectionLink)}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Button aria-label={translate('button.bookExternal')} color="primary">
                          {translate('button.bookExternal')}
                        </Button>
                      </a>
                    );
                  }
                  return (
                    isMisterflyTicket({ allowanceName: allowance.name }) ? (
                      <Button aria-label={translate('button.bookExternal')} color="primary" disabled={hasAlreadyBenefited} onClick={handleReservation}>
                        {translate('button.bookExternal')}
                      </Button>
                    ) : (
                      <Link to={ROUTES.ALLOWANCE_LEISURE_DETAIL_BOOKING(category, id)}>
                        <Button aria-label={translate('button.book')} color="primary">
                          {translate('button.book')}
                        </Button>
                      </Link>
                    )
                  );
                })()}
              </Grid>
            )}
          </Grid>
        </Grid>
      </AllowanceLeisureDetailContainer>
    </Wrapper>
  );
});

export default AllowanceLeisureDetail;